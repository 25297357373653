<div class="nav_wrapper">
    <nav mat-tab-nav-bar>
        <!--<span mat-tab-link>
            <img class="img-circle" src="{{user?.avatar_url}}"> {{login}}
        </span>-->
        <a mat-tab-link routerLink="/github/followers/{{login}}" routerLinkActive="active-link">
            Followers
            <span class="badge">{{user?.followers}}</span>
        </a>
        <a mat-tab-link routerLink="/github/following/{{login}}" routerLinkActive="active-link">
            Following
            <span class="badge">{{user?.following}}</span>
        </a>
        <a mat-tab-link routerLink="/github/repos/{{login}}" routerLinkActive="active-link">
            Repositories
            <span class="badge">{{user?.public_repos}}</span>
        </a>
    </nav>
</div>
<!--<pre>{{user | json}}</pre>-->