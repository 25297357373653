<mat-card class="login-card">
    <mat-card-header>
        <mat-card-title>

        </mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="!user">
        <img [src]="imageUrl" width="200px">
        <br>
        <strong>OAuth 2 Authentication</strong>
        <br>
        <br>
        <p>
            This will redirect you to Google where you will login. The only premission it requests is your email address. Then you are
            added as a user to this Firebase application.
        </p>
    </mat-card-content>
    <mat-card-actions>
        <button *ngIf="!user" mat-raised-button color="accent" (click)="signIn(user)">Login with
            <img src="https://vignette.wikia.nocookie.net/logopedia/images/9/9d/Google_logo_white_2015.svg" width="80px">
        </button>
    </mat-card-actions>
</mat-card>